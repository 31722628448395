import React, { useRef, useEffect } from "react"

import Layout from "../../components/layout"
import HeroTwo from "../../components/Hero/HeroTwo"
import Overview from "../../components/CaseStudy/Overview"
import { SEO } from "../../components/seo"
import TitleContent from "../../components/TitleContent/TitleContent"
import Hello from "../../components/Hello/Hello"
import Newsletter from "../../components/Form/Newsletter"
import ContentImage from "../../components/ContentImage/ContentImage"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import StaticTestimonial from "../../components/Testimonial/StaticTesimonial"
import ShowCase from "../../components/CaseStudy/CaseStudyShowCase"
import { caseStudies } from "."
import ShowcaseSlider from "../../components/ShowcaseSlider"
import { GatsbyImage } from "gatsby-plugin-image"
import CelerityBanner from "../../components/CaseStudy/CelerityBanner"
import StickySLide from "../../components/StickySlide"
if (typeof window !== undefined) {
    gsap.registerPlugin(ScrollTrigger)
}
const techs = [
    { src: "/case-study/hubspot.png" },
    { src: "/case-study/gatsby.png" },
    { src: "/case-study/wordpress.png" },
    { src: "/case-study/gsap.png" },
    { src: "/case-study/tailwind.svg" },
    { src: "/case-study/graphql.png" }
]

const heroData = {
    preheader: "Celerity UK",
    title: "Unleash the Power of Transformation",
    fullWidth: true
}
const overview = {
    overview:
        "At Saigon Digital, we thrive on taking up exciting challenges, and our project with Celerity was indeed a rewarding experience. With their diverse range of IT services, Celerity needed a website that could adapt to their ever-evolving needs. Here's how we transformed their digital landscape. ",
    services: ["Development", "UI/UX", "SEO"],
    linkUrl: "https://celerity-uk.com/"
}
const lhScores = [
    {
        title: "Performance",
        score: 98
    },
    {
        title: "Accessibility",
        score: 94
    },
    {
        title: "Best Practices",
        score: 100
    },
    {
        title: "SEO",
        score: 100
    }
]
const Celerity = ({ location }) => {
    const context = {
        pageName: "SD | Case studies - Celerity Uk",
        pageUri: location.href
    }

    return (
        <Layout context={context}>
            <HeroTwo props={heroData} />
            <Overview {...overview} />
            <CelerityBanner className={"mt-24"} />
            <TitleContent
                title="Celerity's Digital Evolution: JAMstack-Driven Success "
                className="py-14 lg:py-24 "
            >
                <p className="mb-16 lg:mb-20">
                    Discover how Celerity, a dynamic IT service provider,
                    redefined their digital presence with a JAMstack-powered
                    website. From revolutionising functional visual-appealing
                    design to optimising performance, this case study unveils
                    their journey towards a faster, more efficient, and secure
                    web platform. Explore the tech-stack that drove this
                    transformation, along with the results that left Celerity
                    poised for digital success.
                </p>
                <h3 className="mt-6 text-lg font-semibold">
                    Technologies used:
                </h3>
                <div class="mt-6 grid grid-cols-2 gap-4 md:gap-4 lg:grid-cols-3 lg:gap-[30px]">
                    {techs.map(({ src }, index) => {
                        return (
                            <div class="group grid max-h-[74px] min-h-[74px] place-items-center border py-2 px-6 transition-all duration-300 hover:shadow-light_shadow">
                                <img
                                    src={src}
                                    className="max-w-full object-cover grayscale transition-all duration-300 group-hover:grayscale-0"
                                    alt="logos"
                                />
                            </div>
                        )
                    })}
                </div>
            </TitleContent>
            <Hello
                link="https://saigon.digital/blog/advancing-web-security-jamstack-versus-traditional-website-development"
                title="Optimise User-Experience with Google Lighthouse"
                message={"If you want to get started manage with JAMstack, "}
                messageLinkTitle={
                    "check out our JAMstack Versus Traditional Website Development!"
                }
                lhScores={lhScores}
            >
                <p className="mb-4">
                    Google Lighthouse is an open-source tool for website
                    performance, auditing pages for performance, accessibility,
                    SEO, Progressive Web App and Best Practices.
                </p>
                <p className="mb-4">
                    Using Google Lighthouse as a benchmark, we make sure that
                    Celerity's website is performing flawlessly with excellent
                    scores.
                </p>
                <p>
                    Here is the performance results:{" "}
                    <a
                        href="/Celerity-Report.pdf"
                        target="_blank"
                        download
                        className="font-semibold text-primary-color underline"
                    >
                        Google Lighthouse Report
                    </a>
                </p>
            </Hello>
            <TitleContent title="The Challenge" className={"py-24 pt-28"}>
                <p>
                    {" "}
                    Celerity had a unique set of requirements. They needed a
                    website that could be their canvas, where they could feel
                    empowered to create new pages, layouts and edit content with
                    ease. One of the things we love doing here at Saigon Digital
                    is to build tailored CMS solutions for our clients. Celerity
                    was no different, we built a  WordPress backend to allow for
                    easy drag-and-drop editing for their component library. 
                </p>
                <p className="mt-4">
                    Celerity’s new brand refresh and design required a strong
                    set of frontend UI skills to bring the prototypes to life,
                    it was quite the challenge alongside the go live deadline,
                    nevertheless our team at Saigon Digital were more than up
                    for it!
                </p>
            </TitleContent>

            <ShowcaseSlider slides={slides} />

            <TitleContent title="The Solutions" className={"my-28"}>
                <p className="text-base text-[#1D2333]">
                    We love building with the JAMstack - and it’s all about
                    creating modern, fast, and SEO-friendly websites. So, we
                    decided to go with Gatsby.js, which was the clear choice for
                    us as it’s built so well alongside WordPress as a Headless
                    CMS. Our team already has a ton of experience with the
                    Gatsby data layer & GraphQL alongside WordPress, and the
                    requirements for this project favoured this approach.
                </p>
            </TitleContent>
            <div className="container mb-24 grid gap-y-10 gap-x-[30px] md:grid-cols-2 lg:mb-24 lg:gap-x-24">
                <div className="m-0 aspect-[475/356] w-full overflow-hidden lg:ml-5 ">
                    <img
                        src="/case-study/celerity/Celerity-2-col-1.svg"
                        className="h-full w-full duration-1000 hover:scale-105"
                        alt="case study"
                        objectFit="cover"
                    />
                </div>
                <div className="m-0 aspect-[475/356] w-full overflow-hidden lg:mr-5 ">
                    <img
                        src="/case-study/celerity/Celerity-2-col-2.svg"
                        className="h-full w-full object-cover transition-transform duration-1000 hover:scale-105"
                        alt="case study"
                        objectFit="cover"
                    />
                </div>
            </div>
            <Newsletter />
            <TitleContent title="Outcomes" className="mt-20 lg:mt-28">
                <p>
                    The previous Celerity website, which was initially built on
                    WordPress, faced issues with speed and security. To address
                    these concerns, we embarked on a comprehensive renovation,
                    transitioning to a JAMstack architecture. This
                    transformation not only significantly improved the website's
                    speed and overall performance, but also enhanced its
                    security, ensuring a smoother and safer user experience and
                    to future proof the website for years to come.
                </p>
                <p className="mt-4">
                    And that's not all! We also introduced some captivating
                    elements to the website using the GSAP framework and
                    react-spring for animations, making it an even more engaging
                    digital experience.
                </p>
            </TitleContent>

            <StickySLide>
                <ContentImage
                    className="lg:opacity-100"
                    img={"/case-study/celerity/Celerity-Outcome-1.png"}
                >
                    <h2 className="mb-4 text-2xl font-semibold">
                        Harnessing the Power of Flexibility
                    </h2>

                    <ul className="list-disc pl-5">
                        <li>
                            {" "}
                            In the world of JAMstack, architecture is king. Our
                            Celerity project was built on three pillars:
                            client-side JavaScript, server-side APIs, and a
                            database.
                        </li>
                        <li>
                            Gatsby.js took the reins on the client side,
                            generating static HTML pages from React components.
                            WordPress, acting as the Headless CMS, dished out
                            content via Gatsby's data layer & GraphQL APIs.
                        </li>
                        <li>
                            To make styling a breeze, we wielded the mighty
                            Tailwind CSS, a utility-first CSS framework that
                            simplifies component styling.
                        </li>
                    </ul>
                </ContentImage>
                <ContentImage
                    img={"/case-study/celerity/Celerity-Outcome-2.png"}
                >
                    <h2 className="mb-4 text-2xl font-semibold">
                        Choosing the Right Tools
                    </h2>

                    <p className="text-[#1D2333]">
                        For the frontend UI and building the components, our
                        team is a big fan of Tailwind CSS. It makes crafting
                        highly customised user interfaces a breeze, which is
                        ideal for accommodating our client's unique design
                        preferences. Celerity uses HubSpot for their internal
                        Marketing, this allowed us to work alongside the HubSpot
                        form API where created gated content for their resources
                        & downloads so their Marketing team could create high
                        converting landing pages.
                    </p>
                </ContentImage>
                <ContentImage
                    img={"/case-study/celerity/Celerity-Outcome-3.png"}
                >
                    <h2 className="mb-4 text-2xl font-semibold">
                        Crafting Excellent Performance
                    </h2>

                    <p>
                        This project was a resounding success! We took
                        Celerity's unique ideas and transformed them into a
                        website that's fast, secure, and as unique as they are.
                        We're thrilled with the results, and Celerity is now
                        ready to embrace a new era of online success. Saigon
                        Digital is now proud to say we help support the current
                        live website and carry out continued SEO work to keep
                        the website ticking along nicely.
                    </p>
                </ContentImage>
            </StickySLide>
            <TitleContent title={"The Results"} className={"mb-28"}>
                <p>
                    Celerity now boasts a modern and fresh website. With faster
                    load times, improved security, and a flexible CMS with its
                    drag and drop page builder, they're all set to make their
                    digital mark.
                </p>
                <p className="mt-4">
                    Since going live, Celerity has been on a mission to create
                    new pages and content which has been a breeze. A harmonious
                    blend of technology and creativity, our JAMstack approach
                    has set Celerity on the path to digital greatness.
                </p>
            </TitleContent>

            <div className="relative z-10 flex min-h-[40vh] flex-col items-center justify-center py-20 text-center  lg:py-20">
                <img
                    src="/case-study/celerity/result.png"
                    className="absolute inset-0 z-0 h-[calc(100%+5px)] w-full object-cover"
                    alt="background"
                />
                <img
                    src="/case-study/celerity/logo-small.svg"
                    className="z-10 max-w-[136px]"
                    alt="logo small"
                />
                <p className="z-10 mt-10 max-w-[755px] px-5 text-xl font-medium text-white lg:text-2xl">
                    Securely manage and transform your legacy environments
                    within the datacentre and hybrid cloud.
                </p>
            </div>
            <div className=" mt-0">
                <StaticTestimonial
                    title={"Thoughts from the Celerity"}
                    content={
                        <p>
                            Saigon Digital's technical expertise has transformed
                            Celerity's digital presence - the development of our
                            new website has resulted in improvements across user
                            experience, lead generation, and organic search
                            performance. If we need advice or assistance, the
                            response is immediate - it's like having an
                            extension to the team.
                        </p>
                    }
                    author={"Callum Graham-Rack"}
                    position={"Marketing Assistant at Celerity UK"}
                />
            </div>
            <ShowCase data={caseStudies} />
        </Layout>
    )
}

const slides = [
    {
        component: (
            <div className=" relative flex min-h-[50vh] items-center bg-black py-20 lg:max-h-[50vh]  ">
                <img
                    src="/case-study/celerity/slide-1.png"
                    alt="shape-1 "
                    className="absolute inset-0  h-full w-full object-cover"
                />
                <div className="container relative z-10 grid h-full  grid-cols-12 gap-y-5">
                    <div className="col-span-full flex flex-col gap-[30px] md:col-span-7">
                        <img
                            src="/case-study/celerity/celerity.svg"
                            className="max-w-[276px]"
                            alt="logo"
                        />
                        <h4 className="text-xl font-medium text-white lg:text-2xl">
                            We create secure digital worlds for our customers,
                            so you can look ahead with absolute confidence -
                            helping you to make a real, positive impact on the
                            world.
                        </h4>
                    </div>
                </div>
            </div>
        )
    },
    {
        component: (
            <div className=" relative flex min-h-[50vh] items-center bg-black py-20 lg:max-h-[50vh]  ">
                <img
                    src="/case-study/celerity/slide-2.svg"
                    alt="shape-1 "
                    className="absolute inset-0  h-full w-full object-cover"
                />
                <div className="container relative z-10 grid h-full  grid-cols-12 gap-y-5">
                    <div className="col-span-full flex flex-col gap-[30px] md:col-span-7">
                        <img
                            src="/case-study/celerity/celerity.svg"
                            className="max-w-[276px]"
                            alt="logo"
                        />
                        <h4 className="text-xl font-medium text-white lg:text-2xl">
                            We have been creating secure digital worlds for
                            customers for over 20 years.
                        </h4>
                    </div>
                </div>
            </div>
        )
    },
    {
        component: (
            <div className=" relative flex min-h-[50vh] items-center bg-black py-20 lg:max-h-[50vh]  ">
                <img
                    src="/case-study/celerity/slide-3.png"
                    alt="shape-1 "
                    className="absolute inset-0  h-full w-full object-cover"
                />
                <div className="container relative z-10 grid h-full  grid-cols-12 gap-y-5">
                    <div className="col-span-full flex flex-col gap-[30px] md:col-span-7">
                        <img
                            src="/case-study/celerity/celerity.svg"
                            className="max-w-[276px]"
                            alt="logo"
                        />
                        <h4 className="text-xl font-medium text-white lg:text-2xl">
                            We cares about your business success. Understanding
                            that you should never be held back from achieving
                            your ambitions because of your technology.
                        </h4>
                    </div>
                </div>
            </div>
        )
    },
    {
        component: (
            <div className=" relative flex min-h-[50vh] items-center bg-black py-20 lg:max-h-[50vh]  ">
                <img
                    src="/case-study/celerity/slide-4.png"
                    alt="shape-1 "
                    className="absolute inset-0  h-full w-full object-cover"
                />
                <div className="container relative z-10 grid h-full  grid-cols-12 gap-y-5">
                    <div className="col-span-full flex flex-col gap-[30px] md:col-span-7">
                        <img
                            src="/case-study/celerity/celerity.svg"
                            className="max-w-[276px]"
                            alt="logo"
                        />
                        <h4 className="text-xl font-medium text-white lg:text-2xl">
                            Celerity bridges the gap between client and vendor
                            providing benefit from collaborative relationships
                            that have been developed over many years of success
                            with some of the world's most trusted technology
                            vendors.
                        </h4>
                    </div>
                </div>
            </div>
        )
    }
]

export default Celerity


export const Head = () => (
    <SEO
        title={"Celerity UK | Saigon Digital Case Study"}
        description={
            "Transforming Celerity UK's Digital Presence. JAMstack Development with state of the art Web Design and User Expereience."
        }
        keywords={
            "web design saigon, web development saigon, jamstack development, jamstack web development saigon"
        }
    />
)
