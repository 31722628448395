import Blob from "@images/blobanimation.svg"
import React, { useEffect } from "react"
import { useRef } from "react"
import styled from "styled-components"
import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import { StaticImage } from "gatsby-plugin-image"

const Wrapper = styled.section`
    background: linear-gradient(91deg, #180f7d 0%, #27007a 100%);
`

if (typeof window !== "undefinded") {
    gsap.registerPlugin(ScrollTrigger)
}
function CelerityBanner({ className, startColor, endColor, children }) {
    const $wrapper = useRef(null)
    const $img1 = useRef(null)
    const $img2 = useRef(null)
    const $img3 = useRef(null)
    const $img4 = useRef(null)
    useEffect(() => {
        const mm = gsap.matchMedia($wrapper)
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: $wrapper.current,
                once: true,
                // markers: true,
                start: "top bottom-=50%",
                end: "top bottom-=50%"
            }
        })
        mm.add("(min-width: 1024px)", () => {
            tl.from($img1.current, {

                opacity: 0,
                stagger: 0.5
            }).from(
                $img3.current,
                {
                    y: 60,
                    opacity: 0,
                    duration: 0.5,
                    ease: "back"
                },
                0
            ).from(
                $img4.current,
                {
                    y:-60,
                    opacity:0,
                    duration:0.5
                },
            ).from(
                $img2.current,
                {
                    opacity:0,
                    duration:0.5
                }
            )

            gsap.fromTo(
                ".animate-img",
                { yPercent: 10 },
                {
                    yPercent: -60,
                    scrollTrigger: {
                        trigger: $wrapper.current,
                        scrub: 0.8,

                        // markers: true,
                        start: "top bottom-=20%",
                        end: "bottom+=100% top"
                    },
                    duration: 1,
                    ease: "none"
                }
            )
        })

        return () => {
            mm.revert()
        }
    }, [])
    return (
        <div
            $startColor={startColor}
            $endColor={endColor}
            className={`relative py-14 md:py-0 ${className} bg-[#F5F5F5] overflow-y-hidden`}
            ref={$wrapper}
        >
            <img src="/case-study/celerity/shape-1.svg" alt="" className="absolute z-0 inset-0 object-cover w-full h-full" />
            
                <div className="container">
                <div className=" min-h-[460px]  grid grid-cols-12 gap-6 text-center sm:items-start sm:justify-center sm:text-left md:max-w-none h-full">

                  <div className=" lg:flex  col-span-full h-full flex items-center justify-center md:justify-start  md:col-span-6 lg:col-span-7 ">
                  <img ref={$img1} src="/case-study/celerity/celerity.svg" className="w-[80%] max-w-[518px] z-10 object-cover"/> 
                  </div>
                 <div className="col-span-full  -translate-y-8 md:translate-y-0 md:col-span-6 lg:col-span-5 h-full flex ">
                    <div className="flex min-w-[150px] items-center">
                    <img ref={$img2} src="/case-study/celerity/phone-middle.png" className="animate-img translate-y-5 w-full h-full max-h-[312px] z-10 object-cover"/> 
                  </div>
                  <div className="flex  flex-col items-center xl:-translate-x-14 justify-between">
                  <img ref={$img3} src="/case-study/celerity/phone-top.png" className="animate-img md:-translate-y-10 w-full hidden md:scale-[120%] xl:scale-100 md:block h-full max-h-[312px] z-10 object-cover"/> 
                  <img ref={$img4} src="/case-study/celerity/phone-bottom.png" className="animate-img translate-y-10 md:translate-y-5 -translate-x-5 md:translate-x-0 w-full h-full scale-100 md:scale-[120%] xl:translate-y-0 xl:scale-100   z-10 object-cover"/> 
                  </div>
                    </div>
                 </div>
                </div>
                {children}
                </div>
              
    
    
    )
}

export default CelerityBanner

